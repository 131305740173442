import React, { useContext } from "react";
import { Button, Box, Link } from "theme-ui";
import { Scrollbars } from "react-custom-scrollbars";
import Drawer from "components/drawer";
import { DrawerContext } from "contexts/drawer/drawer.context";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link as ScrollLink } from "react-scroll";
import menuItems from "./header.data";
import Logo from "components/logo";
import logoIcon from "assets/logo-icon.png";
// import logoDark from "assets/logo-dark.png";

const MobileDrawer = ({ isHome }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = React.useCallback(() => {
    dispatch({
      type: "TOGGLE",
    });
  }, [dispatch]);

  return (
    <Drawer
      width="320px"
      drawerHandler={
        <Box id="drawer-handler" sx={styles.handler}>
          <IoMdMenu size="22px" />
        </Box>
      }
      open={state.isOpen}
      toggleHandler={toggleHandler}
      closeButton={<IoMdClose size="24px" color="#02073E" />}
      drawerStyle={styles.drawer}
      closeBtnStyle={styles.close}
    >
      <Scrollbars autoHide>
        <Box sx={styles.content}>
          <Logo logo={logoIcon} />
          {isHome && (
            <Box sx={styles.menu}>
              {menuItems.map(({ path, label }, i) => (
                <ScrollLink
                  activeClass="active"
                  to={path}
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                  key={i}
                >
                  {label}
                </ScrollLink>
              ))}
            </Box>
          )}
          {!isHome && (
            <Box sx={styles.menu}>
              {menuItems.map(({ altPath, label }, i) => (
                <Link href={altPath} key={i}>
                  {label}
                </Link>
              ))}
            </Box>
          )}
          <Box sx={styles.menuFooter}>
            <Link href="/contact">
              <Button variant="primary" sx={styles.button}>
                CONTACT US
              </Button>
            </Link>
          </Box>
        </Box>
      </Scrollbars>
    </Drawer>
  );
};

const styles = {
  handler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: "0",
    width: "26px",

    "@media screen and (min-width: 960px)": {
      display: "none",
    },
  },

  drawer: {
    width: "100%",
    height: "100%",
    background: "#fff",
  },

  close: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "30px",
    right: "30px",
    zIndex: "1",
  },

  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    pt: "30px",
    pb: "40px",
    px: "30px",
    "#logo-dark": { width: "20%", mx: "auto" },
  },

  menu: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",

    a: {
      fontSize: "16px",
      fontWeight: "400",
      color: "black",
      py: "5px",
      cursor: "pointer",
    },
  },

  menuFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: "auto",
  },

  button: {
    fontSize: "15px",
    fw: "700",
    height: "48px",
    borderRadius: "3px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    py: "0",
    backgroundColor: "primary",
    color: "#fff",
  },
};

export default MobileDrawer;
